import { CCard, CCardGroup, CCol, CRow } from '@coreui/react';
import React, { useEffect } from 'react';
import { DraftShield } from '../../data/types';
import DraftShieldSelectionItem, { DraftShieldSelectionItemDTO } from './DraftShieldSelectionItem';
import styles from './DraftShieldSelection.module.scss';
import { useDraftShieldSelectionList } from './hooks/useDraftShieldSelectionList';
import AttentionIcon from '../../assets/icons/attention.svg';
import { useTranslation } from 'react-i18next';
import HelpTextButton from '../HelpTextButton';
import DraftShieldHelpText from './DraftShieldHelpText';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import { currentStepState } from '../../states';

const SHIELDS_IN_ROW = 3;

const DraftShieldSelectionList: React.FC<Record<string, never>> = (): JSX.Element => {
  const {
    config,
    compatibleDraftShieldNames,
    onDraftShieldSelect,
    onUndoSelection,
    isStep1Completed,
    allDraftShieldsGrouped,
    selectedCategory,
  } = useDraftShieldSelectionList(SHIELDS_IN_ROW, window.innerWidth <= 768);

  const { t } = useTranslation('common');
  const [, setCurrentStep] = useRecoilState(currentStepState);

  useEffect(
    () => {
      setCurrentStep(2);
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      {!isStep1Completed && (
        <CRow className="mb-4" data-testid="choose-balance-first">
          <CCol lg="12">
            <img src={AttentionIcon} className="mr-2" />
            {t('draftShieldSelection.noBalance')}
          </CCol>
        </CRow>
      )}
      <HelpTextButton title={t('helpText.step2.heading')} content={<DraftShieldHelpText />} />
      <CRow>
        <CCol lg="12">
          {allDraftShieldsGrouped.map((draftShieldGroup: DraftShield[], index) => {
            return (
              <CCardGroup key={`group-${index}`}>
                {draftShieldGroup.map((draftShield: DraftShield, index) => {
                  const selectionItemData: DraftShieldSelectionItemDTO = {
                    name: draftShield.name,
                    label: draftShield.label,
                    description: draftShield.description,
                    imageURL: draftShield.image,
                    enabled: isStep1Completed,
                    selected: draftShield.name === config.draftShield?.name,
                    compatible: compatibleDraftShieldNames.includes(draftShield.name),
                    lastInRow: index === SHIELDS_IN_ROW - 1,
                  };

                  return (
                    <DraftShieldSelectionItem
                      key={draftShield.name}
                      itemData={selectionItemData}
                      selectedCategory={selectedCategory}
                      onDraftShieldSelect={onDraftShieldSelect(draftShield)}
                      onUndoSelection={onUndoSelection}
                    />
                  );
                })}
                {draftShieldGroup.length < SHIELDS_IN_ROW &&
                  _.times(SHIELDS_IN_ROW - draftShieldGroup.length, () => (
                    <CCard className={`${styles.hiddenCard} data-hj-allow mb-5`} />
                  ))}
              </CCardGroup>
            );
          })}
        </CCol>
      </CRow>
    </>
  );
};

export default DraftShieldSelectionList;
