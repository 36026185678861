import { useRecoilState } from 'recoil';
import { configurationState, navigationStepStatusState } from '../../../states';
import { getAllDraftShields, getCompatibleDraftShieldNames } from '../../../services/draftShield';
import { DraftShield } from '../../../data/types';
import _ from 'lodash';
import { getModuleDetails } from '../../../services/modules';
import { SelectedCategory, UseDraftShieldSelection } from '../types';
import { DraftShieldEvent } from '../../../types/tagManagerEvents';
import { NavigationSlugs } from '../../../types/navigation';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { sendGoogleTagManagerEvent, trackPageImpression } from '../../../services/googleTagManager';

export const useDraftShieldSelectionList = (
  numberOfRows: number,
  shouldSortCompatibleFirst: boolean,
): UseDraftShieldSelection => {
  const [config, setConfig] = useRecoilState(configurationState);
  const [navigationStepStatus, setNavigationStepStatus] = useRecoilState(navigationStepStatusState);
  const isStep1Completed = navigationStepStatus.step1 == 'complete';
  const history = useHistory();

  let compatibleDraftShieldNames: DraftShield['name'][] = [];
  if (config.module) {
    compatibleDraftShieldNames = getCompatibleDraftShieldNames(config.module);
  }
  const allDraftShields =
    compatibleDraftShieldNames.length > 0 && shouldSortCompatibleFirst
      ? getAllDraftShields().sort((a, b) => {
          const aIndex = compatibleDraftShieldNames.findIndex((item) => item === a.name);
          const bIndex = compatibleDraftShieldNames.findIndex((item) => item === b.name);
          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;
          return aIndex - bIndex;
        })
      : getAllDraftShields();
  const allDraftShieldsGrouped: DraftShield[][] = _.chunk(allDraftShields, numberOfRows);

  useEffect(() => {
    trackPageImpression(2);
  }, []);

  useEffect(() => {
    return () => {
      trackDraftShieldSelection();
    };
    // eslint-disable-next-line
  }, [config]);

  let selectedCategory: SelectedCategory;
  if (config.module) {
    const moduleDetails = getModuleDetails(config.module.name);
    selectedCategory = moduleDetails ? moduleDetails.categoryName : undefined;
  }

  const setDraftShield = (selectedDraftShield: DraftShield) => {
    setConfig({
      ...config,
      displayModule: undefined,
      softwarePackage: undefined,
      softwareOptions: undefined,
      hardwarePackage: undefined,
      draftShield: selectedDraftShield,
    });
  };

  function onDraftShieldSelect(draftShield: DraftShield) {
    return function () {
      setDraftShield(draftShield);
      setNavigationStepStatus({ ...navigationStepStatus, step2: 'complete', step3: 'not_set' });
    };
  }

  const trackDraftShieldSelection = () => {
    if (history.location.pathname.includes('/step3') && config.draftShield) {
      const trackingEvent: DraftShieldEvent = {
        event: 'cubisCalculatorSelection',
        'cubis-config-slug': NavigationSlugs[2],
        'cubis-config-step': 2,
        'cubis-config-shield': `${config.draftShield.label} (${config.draftShield.name})`,
      };

      sendGoogleTagManagerEvent(trackingEvent);
    }
  };

  function onUndoSelection() {
    setConfig({
      ...config,
      draftShield: undefined,
    });
    setNavigationStepStatus({ ...navigationStepStatus, step2: 'incomplete' });
  }

  return {
    config,
    compatibleDraftShieldNames,
    allDraftShieldsGrouped,
    isStep1Completed,
    onDraftShieldSelect,
    onUndoSelection,
    selectedCategory,
  };
};
